import { createContextForController } from 'react-controller-context';
import React from 'react';

export type FeatureId = string;

export interface IFeature {
    dev: boolean;
    stage: boolean;
    production: boolean;
}

interface IFeaturesCatalog {
    [key: FeatureId]: IFeature;
}

const emptyFeature: IFeature = {
    //  If you turn this off, you shouldnt see your feature any more
    dev: true,
    stage: false,
    production: false
};

const useController = () => {
    const [ featuresCatalog, setFeaturesCatalog ] = React.useState<IFeaturesCatalog>({});

    //  We can always add some service call here that will override features in the future

    //  Simple function to upsert a feature
    const setFeature = (id: FeatureId, feature: Partial<IFeature>) => setFeaturesCatalog(
        existingFeatures => ({
            ...existingFeatures,
            [id]: {
                ...emptyFeature,
                ...feature
            }
        })
    );

    //  Retrieves a feature from the catalog
    const getFeature = React.useCallback(
        (id: FeatureId) => featuresCatalog[id] || emptyFeature,
        [ featuresCatalog ]
    );

    return {
        setFeature,
        getFeature
    };
};

const context = createContextForController(useController);
export const FeaturesProvider = context.Provider;
export const useFeatures = context.useController;