import { useQuery } from '@tanstack/react-query';
import { loggedInUser, useOrgService } from 'hooks';
import { MILLISECONDS, IStaffMember } from 'types';

interface StaffMembersCache {
  staffMembers: IStaffMember[];
  isStaffMembersLoading: boolean;
}

export const useStaffMembersCache = (): StaffMembersCache => {
  const orgService = useOrgService();
  const { isLoggedIn } = loggedInUser.useController();

  const staffMembersQuery = useQuery({
    queryKey: ['staffMembers'],
    queryFn: () => orgService.getUsers(),
    staleTime: MILLISECONDS.HOUR * 1,
    enabled: isLoggedIn
  });

  const staffMembers = staffMembersQuery.data ? staffMembersQuery.data : [];
  const isStaffMembersLoading = staffMembersQuery.isLoading;

  return {
    staffMembers,
    isStaffMembersLoading
  };
};
