import { useActivityService } from 'hooks';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { MILLISECONDS, IActivity } from 'types';
import taskContext from 'views/Member/useTaskContext';

export interface TaskActivitiesCache {
  isActivitiesLoading: boolean;
  taskActivities: IActivity[];
  refreshActivities: () => void;
}

export const useTaskActivitiesCache = (): TaskActivitiesCache => {
  const { currentMemberTask } = taskContext.useController();
  const activityService = useActivityService();
  const queryClient = useQueryClient();
  const queryKey = ['taskActivities', currentMemberTask?.taskId];

  const taskActivitiesQuery = useQuery({
    queryKey: queryKey,
    queryFn: () => activityService.getTaskActivities(currentMemberTask?.taskId!),
    enabled: Boolean(currentMemberTask?.taskId),
    refetchInterval: MILLISECONDS.MINUTE * 5
  });

  const refreshActivities = () => {
    queryClient.invalidateQueries({ queryKey: queryKey });
  };

  const taskActivities = taskActivitiesQuery.data ? taskActivitiesQuery.data : [];
  const isActivitiesLoading = taskActivitiesQuery.isLoading;

  return {
    taskActivities,
    isActivitiesLoading,
    refreshActivities
  };
};
