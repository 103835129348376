import { ITeam, IRegion, IEntity, IStaffMember } from 'types';
import ApiService from './api-service';

export class OrgService {
  protected apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  getSollisRegions(): Promise<IRegion[]> {
    return this.apiService.get(`/v1/regions`).then((r) => r?.data);
  }

  getUsers(): Promise<IStaffMember[]> {
    return this.apiService.get('v1/GetUsers').then((r) =>
      r?.data.map((e: IStaffMember) => {
        return { ...e, type: 'User' };
      })
    );
  }

  getGroups(): Promise<ITeam[]> {
    return this.apiService.get('/v1/GetGroups').then((r) =>
      r?.data.map((t: ITeam) => {
        return { ...t, type: 'Team' };
      })
    );
  }

  getTeamMembersForTeam(teamId: string): Promise<IEntity[]> {
    return this.apiService.get(`v1/GetGroupMembers/${teamId}`).then((r) =>
      r?.data.map((m: IEntity) => {
        return { ...m, type: 'TeamMember' };
      })
    );
  }

  getTeamMembersForTeams(teamIds: string[]): Promise<IEntity[]> {
    return Promise.all(teamIds.map((teamId) => this.getTeamMembersForTeam(teamId)))
      .then((responses: any[]) => {
        let teamMembers: IEntity[] = responses.flat();
        let uniqueTeamMembers = [...new Set(teamMembers)];

        return uniqueTeamMembers;
      })
      .catch((error) => {
        throw new Error();
      });
  }
}

export default OrgService;
