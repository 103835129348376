import { Grid, Link } from '@mui/material';
import { SearchActivity } from '.';
import activityContext from '../../use-recent-activity';
import { FilterActivity } from './FilterActivity';

export const SearchFilterActivity = () => {
  const { hasActivities, hasFilters, setShowFilters, showFilters, resetFilters } = activityContext.useController();

  if (!hasActivities) {
    return null;
  }

  return (
    <>
      <Grid container columns={2}>
        <Grid item xs={1} textAlign="left">
          <Link
            component="button"
            disabled={!hasFilters}
            sx={{ display: showFilters ? 'block' : 'none' }}
            underline="none"
            onClick={resetFilters}
          >
            {`Reset Filters`}
          </Link>
        </Grid>
        <Grid item xs={1} textAlign="right">
          <Link component="button" underline="none" onClick={() => setShowFilters(!showFilters)}>
            {`${!showFilters ? 'Show' : 'Hide'} Filters`}
          </Link>
        </Grid>
      </Grid>
      <Grid sx={{ mb: 2 }} container rowSpacing={2}>
        <Grid item xs={12}>
          <SearchActivity />
        </Grid>
        <FilterActivity />
      </Grid>
    </>
  );
};
