import { createContextForController } from 'react-controller-context';
import { useSelector } from 'store';
import settings from 'constants/appsettings.json';
import { taskSourceEnum } from 'types/store/taskEnums';
import { useLocation } from 'react-router';

export interface UnknownMemberContext {
  isUnknownMember: boolean;
  unknownMemberSourceLabel: string;
  unknownMemberSourceValue: string;
}

export const useUnknownMemberController = (): UnknownMemberContext => {
  const location = useLocation();
  const { currentMemberTask } = useSelector((state) => state.member);
  const isUnknownMember = location?.state?.paramsData?.memberId === settings.athena.unknownMember;

  let sourceLabel = '';
  switch (currentMemberTask?.taskSourceName) {
    case taskSourceEnum.Email:
      sourceLabel = 'Email';
      break;
    case taskSourceEnum.SMS:
      sourceLabel = 'Text';
      break;
    default:
      sourceLabel = 'Phone';
  }

  const sourceValue = currentMemberTask?.originatingSource ?? '';

  return {
    isUnknownMember,
    unknownMemberSourceLabel: sourceLabel,
    unknownMemberSourceValue: sourceValue
  };
};

const unknownMemberContext = createContextForController(useUnknownMemberController);

export default unknownMemberContext;
