import React from 'react';
import { FeatureId, useFeatures } from './features-provider';
import { useEnvironment } from 'hooks';

interface Props {
    featureId: FeatureId;
    children: React.ReactNode;
}

const useFeature = (id: FeatureId) => {
    const { getFeature } = useFeatures();
    const environment = useEnvironment();
    const visible = React.useMemo(
        () => {
            const feature = getFeature(id);
            if (environment.isProd) {
                return feature.production;
            }
            return feature.dev;
        },
    [getFeature, id, ]);
    return visible;
}

export const Feature = ({
    featureId,
    children
}: Props) => {
    const visible = useFeature(featureId);
    if (!visible) {
        return null;
    }

    return (<>{children}</>);
};
