import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export const ReactQueryClientProvider = (props: Props) => {
  const queryClient = React.useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            retry: 2
          }
        }
      }),
    []
  );

  return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
};

export default ReactQueryClientProvider;
