import {
  ILinkedTask,
  IMember,
  ITask,
  IMemberContact,
  IMemberAccout,
  MemberTreeDetail,
  IMemberFeedback,
  IMemberNote,
  IMemberAccountNote
} from 'types';
import ApiService from './api-service';
import parsePhoneNumber from 'libphonenumber-js';
import moment from 'moment';
import humanParser from 'humanparser';
import { QueryStringBuilder } from './query-string-builder';

export class MemberService {
  protected apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  findMembers(searchString: string = ''): Promise<IMember[]> {
    const queryStringBuilder = new QueryStringBuilder();
    //  is phone number?
    const phoneNumberParseResult = parsePhoneNumber(searchString, 'US');
    if (phoneNumberParseResult && phoneNumberParseResult.isValid()) {
      queryStringBuilder.addParam('phoneNumber', phoneNumberParseResult.number);
    }

    //  is date?
    const date = moment(searchString);
    if (date.isValid() && date.year() > 1900 && date.year() < 9999) {
      queryStringBuilder.addParam('dateOfBirth', date.format('YYYY-MM-DD'));
    }

    //  is name?
    if (!queryStringBuilder.hasParams()) {
      const human = humanParser.parseName(searchString);
      if (human && human.firstName && human.lastName) {
        queryStringBuilder.addParam('searchFirstName', human.firstName);
        queryStringBuilder.addParam('searchLastName', human.lastName);
      }
    }

    //  catch-all
    if (!queryStringBuilder.hasParams()) {
      queryStringBuilder.addParam('searchString', searchString.trim());
    }

    return this.apiService.get(`member/v1/search?${queryStringBuilder.queryString}`).then((r) => r?.data);
  }

  findContacts(memberId: number, searchTerm: string): Promise<IMemberContact[]> {
    return this.apiService.post(`member/v1/contactSearch`, { memberId, searchTerm }).then((r) => r?.data);
  }

  loadMemberTasks(memberId: number): Promise<ITask[]> {
    return this.apiService.get(`tasks/v1/Task/member/${memberId}`).then((r) => r?.data);
  }

  loadLinkableTasks(memberId: number): Promise<ILinkedTask[]> {
    return this.apiService.get(`tasks/v1/Task/member/${memberId}/linkableTasks`).then((r) => r?.data);
  }

  getMemberAccount(accountId: string): Promise<IMemberAccout> {
    return this.apiService.get(`member/v1/Account/${accountId}`).then((r) => r?.data);
  }

  getMemberTree(memberId: number, accountId: string): Promise<MemberTreeDetail[]> {
    return this.apiService.get(`member/v1/${memberId}/memberTree/${accountId}`).then((r) => r?.data);
  }

  getMemberFeedback(memberId: number): Promise<IMemberFeedback[]> {
    return this.apiService.get(`member/v1/getFeedbackForMember?memberId=${memberId}`).then((r) => r?.data);
  }

  getMemberNotes(memberId: number): Promise<IMemberNote> {
    return this.apiService.get(`member/v1/${memberId}/notes`).then((r) => r?.data);
  }

  updateNote(memberId: number, noteContent?: string): Promise<string> {
    return this.apiService.put(`member/v1/${memberId}/note`, { noteContent: noteContent }).then((r) => r?.data);
  }

  getMemberAccountNote(accountId: string): Promise<IMemberAccountNote[]> {
    return this.apiService.get(`member/v1/Account/${accountId}/notes`).then((r) => r?.data);
  }
}

export default MemberService;
