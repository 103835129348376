import { useTaskLookupsCache } from 'hooks';
import { createContextForController } from 'react-controller-context';
import { useSelector } from 'store';
import { taskStatusEnum } from 'types/store/taskEnums';

export const useTaskController = () => {
  const { currentMemberTask } = useSelector((state) => state.member);
  const { isCacheLoaded, isCacheLoading } = useTaskLookupsCache();

  return {
    currentMemberTask,
    isTaskClosed: currentMemberTask?.taskStatusId === taskStatusEnum.Closed,
    isLookupsLoading: isCacheLoading,
    hasLookupsLoaded: isCacheLoaded
  };
};

const taskContext = createContextForController(useTaskController);

export default taskContext;
