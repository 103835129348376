import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemberService } from 'hooks';
import { MILLISECONDS, ITask } from 'types';
import { useSelector } from 'store';
import unknownMemberContext from 'views/Member/components/MemberInfo/useUnknownMember';

interface MemberTasksCache {
  memberTasks: ITask[];
  isLoading: boolean;
  hasTasks: boolean;
  updateMemberTaskCache: (updatedTask: ITask) => void;
  refreshMemberTasks: () => void;
}

export const useMemberTasksCache = (): MemberTasksCache => {
  const { member } = useSelector((state) => state.member);
  const { isUnknownMember } = unknownMemberContext.useController();
  const memberService = useMemberService();
  const queryClient = useQueryClient();

  const memberTaskQuery = useQuery({
    queryKey: ['memberTasks', member?.memberId],
    queryFn: () => memberService.loadMemberTasks(member?.memberId!),
    enabled: Boolean(member?.memberId) && !isUnknownMember,
    staleTime: 0,
    refetchInterval: MILLISECONDS.MINUTE * 1
  });

  const refreshMemberTasks = () => {
    queryClient.invalidateQueries({ queryKey: ['memberTasks'] });
  };

  const updateMemberTaskCache = (updatedTask: ITask) => {
    // Only update member tasks if task is for current member and not unknownMember
    // If member is changed, query will reload anyway with new member
    if (isUnknownMember || Boolean(updatedTask.memberId !== member?.memberId)) {
      return;
    }

    queryClient.setQueryData(['memberTasks', updatedTask.memberId], (oldData: ITask[]) => {
      let updatedData = [...oldData];
      const taskIndex = oldData.map((m) => m.taskId).indexOf(updatedTask.taskId);
      if (taskIndex !== -1) {
        updatedData.splice(taskIndex, 1);
      }
      updatedData.unshift(updatedTask);
      return updatedData;
    });
  };

  const memberTasks = memberTaskQuery.data ? memberTaskQuery.data : [];
  const isLoading = memberTaskQuery.isLoading;
  const hasTasks = Boolean(memberTasks.length);

  return {
    memberTasks,
    isLoading,
    hasTasks,
    updateMemberTaskCache,
    refreshMemberTasks
  };
};
