import { MILLISECONDS, IAdditionalFieldValue, ITask, ITaskUpsert, IUpdateTaskAssignedTo } from 'types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { loggedInUser, useMemberTasksCache, useTaskService } from 'hooks';
import { useMemo } from 'react';

interface TeamTasksCache {
  isTasksLoading: boolean;
  isTasksRefreshing: boolean;
  teamTasks: ITask[];
  hasTasks: boolean;
  teamTasksCount: number;
  myTasksCount: number;
  handleUpdateAssignedTo: (assignedTo: IUpdateTaskAssignedTo) => Promise<void>;
  handleUpsertTask: (newTask: ITaskUpsert) => Promise<ITask>;
  refreshTeamTasks: () => void;
  reloadTeamTasks: () => void;
  updateTeamTaskCache: (updatedTask: ITask) => void;
}

export const useTeamTasksCache = (teamId?: string): TeamTasksCache => {
  const { userId, hasTeams, myTeams } = loggedInUser.useController();
  const { updateMemberTaskCache } = useMemberTasksCache();
  const teamTaskIds = useMemo(
    () => (!hasTeams ? [] : !Boolean(teamId) || teamId === 'ALL' ? myTeams.map((t) => t.id) : [teamId!]),
    [teamId, myTeams, hasTeams]
  );
  const taskService = useTaskService();
  const queryClient = useQueryClient();

  const teamTasksQuery = useQuery({
    queryKey: ['teamTasks', teamTaskIds],
    queryFn: () => taskService.getTeamTasks(teamTaskIds),
    enabled: Boolean(teamTaskIds.length),
    staleTime: 0,
    refetchInterval: MILLISECONDS.SECOND * 30
  });

  // Refresh tasks invalidates the query and acts as a refetch
  const refreshTeamTasks = () => {
    queryClient.invalidateQueries({ queryKey: ['teamTasks'] });
  };

  // Reload tasks resets the query and acts like an initial fetch
  const reloadTeamTasks = () => {
    queryClient.resetQueries({ queryKey: ['teamTasks'] });
  };

  const updateTeamTaskCache = (updatedTask: ITask) => {
    queryClient.setQueryData(['teamTasks', teamTaskIds], (oldData: ITask[]) => {
      let updatedData = [...oldData];
      const taskIndex = oldData.map((m) => m.taskId).indexOf(updatedTask.taskId);
      if (taskIndex !== -1) {
        updatedData.splice(taskIndex, 1);
      }
      if (teamTaskIds.includes(updatedTask.assignedToGroupId!)) {
        updatedData.unshift(updatedTask);
      }
      return updatedData;
    });
  };

  const handleUpdateAssignedTo = (assignedTo: IUpdateTaskAssignedTo) => {
    return taskService.updateAssignedTo(assignedTo).then((taskResponse) => {
      updateTeamTaskCache(taskResponse);
    });
  };

  const handleUpsertTask = (newTask: ITaskUpsert) => {
    return taskService.upsertTask(newTask).then((taskResponse) => {
      let additionalFieldData = {};
      taskResponse.additionalFieldValues?.forEach((f: IAdditionalFieldValue) => {
        if (f.fieldValue) {
          Object.assign(additionalFieldData, {
            [f.fieldName]: f.fieldValue
          });
        }
      });

      const upsertedTask = {
        ...taskResponse,
        additionalFieldData: additionalFieldData
      };

      updateMemberTaskCache(upsertedTask);

      return upsertedTask;
    });
  };

  const teamTasks = teamTasksQuery.data ? teamTasksQuery.data : [];
  const isTasksLoading = teamTasksQuery.isPending || teamTasksQuery.isLoading;
  const isTasksRefreshing = teamTasksQuery.isRefetching;
  const hasTasks = Boolean(teamTasks.length);
  const myTasksCount = teamTasks.filter((t) => t.assignedToUserId === userId).length;
  const teamTasksCount = teamTasks.length;

  return {
    isTasksLoading,
    isTasksRefreshing,
    teamTasks,
    hasTasks,
    myTasksCount,
    teamTasksCount,
    handleUpsertTask,
    handleUpdateAssignedTo,
    refreshTeamTasks,
    reloadTeamTasks,
    updateTeamTaskCache
  };
};

export default useTeamTasksCache;
