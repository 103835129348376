import { createSlice, current } from '@reduxjs/toolkit';
import { IChatState, IMessage } from 'types';

const initialChatState: IChatState = {
  chatMessages: [],
  isChatLoading: false
};

const slice = createSlice({
  name: 'chat',
  initialState: initialChatState,
  reducers: {
    loadChatMessages(state, action) {
      const currentState = current(state);
      const newChat = action.payload;
      const currentChatMessages = currentState.chatMessages;

      const newChatObjectMessages = newChat.messages.filter((m: IMessage) => currentChatMessages.find((cm) => cm.id === m.id) == null);
      const newExistingChatObjectMessages = newChat.messages.filter(
        (m: IMessage) => currentChatMessages.find((cm) => cm.id === m.id) != null
      );
      let newChatMessages = [...currentChatMessages, ...newChatObjectMessages];

      // Update existing messages with the new message coming in.
      // This handles message reactions and messages edited in teams.
      newChatMessages = newChatMessages.map((chatMessage: IMessage) => {
        const newExistingMessage = newExistingChatObjectMessages.find((m: IMessage) => m.id === chatMessage.id);
        if (newExistingMessage) {
          return newExistingMessage;
        } else {
          return chatMessage;
        }
      });

      const sortedChats = newChatMessages.sort((a, b) => {
        const aDate = new Date(a.createdDate);
        const bDate = new Date(b.createdDate);
        return bDate.getTime() - aDate.getTime();
      });

      state.chatMessages = sortedChats;
      state.isChatLoading = false;
    },

    addMessageToChat(state, action) {
      const currentState = current(state);
      const newMessage = action.payload;

      let updatedMessages = currentState.chatMessages;
      const newPendingMessageIndex = currentState.chatMessages.findIndex((m) => m.identifier === newMessage.identifier);
      if (newPendingMessageIndex === -1) {
        updatedMessages = [...updatedMessages, newMessage];
      } else {
        updatedMessages = [
          ...updatedMessages.slice(0, newPendingMessageIndex),
          newMessage,
          ...updatedMessages.slice(newPendingMessageIndex + 1)
        ];
      }

      const sortedChats = updatedMessages.sort((a, b) => {
        const aDate = new Date(a.createdDate);
        const bDate = new Date(b.createdDate);
        return bDate.getTime() - aDate.getTime();
      });

      state.chatMessages = sortedChats;
    },

    setIsChatLoading(state, action) {
      state.isChatLoading = action.payload;
    },

    clearChats(state) {
      return {
        ...initialChatState
      };
    }
  }
});

export default slice.reducer;
export const { loadChatMessages, addMessageToChat, setIsChatLoading, clearChats } = slice.actions;
