import { IMMSFiles, ISMS, ISMSRequest } from 'types';
import ApiService from './api-service';

interface PaginatedSMSList {
  textMessages: ISMS[];
  textMessagesCount: number;
  pageSize: number;
  pageIncrement: number;
  totalMessageCount: number;
}

export class SMSService {
  protected apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  getMemberTexts(memberId: number, search: string, pageIncrement: number, pageSize: number): Promise<PaginatedSMSList> {
    return this.apiService
      .post(`Messaging/v1/sms/smsMessages`, {
        memberId,
        pageSize,
        pageIncrement,
        search
      })
      .then((r) => r?.data);
  }

  getTaskTexts(taskId: string, pageIncrement: number, pageSize: number): Promise<PaginatedSMSList> {
    return this.apiService
      .post(`Messaging/v1/sms/smsMessages`, {
        taskId,
        pageSize,
        pageIncrement
      })
      .then((r) => r?.data);
  }

  getFilesForText(messageId: number): Promise<IMMSFiles[]> {
    return this.apiService.get(`Messaging/v1/sms/getMessageMedia?messageId=${messageId}`).then((r) => r.data);
  }

  sendText(textRequest: ISMSRequest): Promise<ISMS> {
    return this.apiService.post(`Messaging/v1/sms/sendSmsMessage`, textRequest).then((r) => r.data);
  }

  isTargetOptedOut(toNumber: ISMS['toNumber']): Promise<boolean> {
    return this.apiService.get(`Messaging/v1/sms/optOutStatus?phoneNumber=${toNumber}`).then((r) => r.data.hasOptedOut);
  }
}

export default SMSService;
