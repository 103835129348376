import { useTaskService } from 'hooks';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { MILLISECONDS, ITaskHistoryRecord } from 'types';
import taskContext from 'views/Member/useTaskContext';

export interface TaskHistoryCache {
  isHistoryLoading: boolean;
  taskHistoryRecords: ITaskHistoryRecord[];
  refreshHistory: () => void;
}

export const useTaskHistoryCache = (): TaskHistoryCache => {
  const { currentMemberTask } = taskContext.useController();
  const taskService = useTaskService();
  const queryClient = useQueryClient();
  const queryKey = ['taskHistory', currentMemberTask?.taskId];

  const taskHistoryQuery = useQuery({
    queryKey: queryKey,
    queryFn: () => taskService.getTaskHistory(currentMemberTask?.taskId!),
    enabled: Boolean(currentMemberTask?.taskId),
    refetchInterval: MILLISECONDS.MINUTE * 5
  });

  const refreshHistory = () => {
    queryClient.invalidateQueries({ queryKey: queryKey });
  };

  const taskHistoryRecords = taskHistoryQuery.data ? taskHistoryQuery.data : [];
  const isHistoryLoading = taskHistoryQuery.isLoading;

  return {
    taskHistoryRecords,
    isHistoryLoading,
    refreshHistory
  };
};
