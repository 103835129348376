import {
  ILinkedTask,
  ITask,
  ITaskHistoryRecord,
  ITaskLookupType,
  ITaskParticipant,
  ITaskType,
  ITaskUpsert,
  IUpdateTaskAssignedTo
} from 'types';
import ApiService from './api-service';
import { QueryStringBuilder } from './query-string-builder';
import { getSlaStatus } from 'utils/helpers/slaStatusHelper';

export class TaskService {
  protected apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  setSLAStatus(task: ITask): ITask {
    let slaStatus = getSlaStatus(task);

    return {
      ...task,
      slaStatus
    };
  }

  mapSLAStatuses(tasks: ITask[]): ITask[] {
    return tasks.map((t: ITask) => this.setSLAStatus(t));
  }

  getTask(taskId: string): Promise<ITask> {
    return this.apiService.get(`tasks/v1/Task/${taskId}`).then((r) => r?.data);
  }

  getTeamTasks(teamIds: string[]): Promise<ITask[]> {
    const encodedUrlParams = this.apiService.encodeQueryParam('groupIds', teamIds);
    return this.apiService.get(`tasks/v1/Task/teamTasks?${encodedUrlParams}`).then((r) => this.mapSLAStatuses(r?.data));
  }

  upsertTask(newTask: ITaskUpsert): Promise<ITask> {
    if (newTask.taskId == null) {
      return this.apiService.post(`tasks/v1/Task`, newTask).then((r) => this.setSLAStatus(r?.data));
    }
    return this.apiService.put(`tasks/v1/Task`, newTask).then((r) => this.setSLAStatus(r?.data));
  }

  updateAssignedTo(assignedTo: IUpdateTaskAssignedTo): Promise<ITask> {
    return this.apiService.put(`tasks/v1/Task/assignedTo`, assignedTo).then((r) => this.setSLAStatus(r?.data));
  }

  addParticipants(task: ITask, participants: string[]): Promise<ITaskParticipant[]> {
    const newParticipants = [...new Set(participants)];
    const request = {
      userIds: newParticipants
    };
    return this.apiService.put(`tasks/v1/Task/${task.taskId}/addParticipant`, request).then((r) => r?.data);
  }

  findTasks(searchString: string = ''): Promise<ILinkedTask[]> {
    const queryStringBuilder = new QueryStringBuilder();
    queryStringBuilder.addParam('searchString', searchString.trim());

    return this.apiService.get(`tasks/v1/Task/searchTasks?${queryStringBuilder.queryString}`).then((r) => r?.data);
  }

  getTaskHistory(taskId: string): Promise<ITaskHistoryRecord[]> {
    return this.apiService.get(`tasks/v1/Task/${taskId}/history`).then((r) => r?.data);
  }

  getTaskTypes(): Promise<ITaskType[]> {
    return this.apiService.get('tasks/v1/Lookup/types').then((r) => r?.data);
  }

  getTaskStatuses(): Promise<ITaskLookupType[]> {
    return this.apiService.get('tasks/v1/Lookup/statuses').then((r) => r?.data);
  }

  getTaskPriorities(): Promise<ITaskLookupType[]> {
    return this.apiService.get('tasks/v1/Lookup/priorities').then((r) => r?.data);
  }

  getTaskClosedReason(): Promise<ITaskLookupType[]> {
    return this.apiService.get('tasks/v1/Lookup/closedReasons').then((r) => r?.data);
  }

  getTaskArchiveTimings(): Promise<ITaskLookupType[]> {
    return this.apiService.get('tasks/v1/Lookup/archiveTimings').then((r) => r?.data);
  }

  cleanupTaskChats(): Promise<void> {
    const request = { maxChatCount: 20 };
    return this.apiService.post(`tasks/v1/Task/cleanupTaskChats`, request).then(() => {});
  }
}

export default TaskService;
