import { ITaskTypeField } from './taskAdditionalFields';

export interface IRegion {
  id: number;
  regionName: string;
  phoneNumber: string;
  smsNumber: string;
  isCorporate: boolean;
}

export interface ITeam extends IEntity {
  defaultMailbox?: string;
  defaultSmsNumber?: string;
  regionId?: number;
  regionName?: string;
}

export interface IStaffMember extends IEntity {
  jobTitle?: string;
}

export interface IEntity {
  id: string;
  displayName: string;
  type: string;
}

export interface ITaskType {
  id: number;
  description: string;
  additionalFields?: ITaskTypeField[];
  category?: string;
  isActive?: boolean;
}

export interface ITaskLookupType {
  id: number;
  description: string;
  isActive?: boolean;
}

export interface IRootState {
  isLoggingOut: boolean;
  isUIVersionOutOfDate: boolean;
  navigationState: { taskId?: string; memberId?: number; createMode?: string };
  errorMessage: string;
  snackState: ISnackState;
}

export interface ISnackState {
  open: boolean;
  isSuccess: boolean;
  message: string;
}

export enum SLA_STATUS {
  BREACH = 'Breach',
  WARNING = 'Warning',
  COMPLIANT = 'Compliant'
}
