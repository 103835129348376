import { Autocomplete, TextField, InputAdornment } from '@mui/material';
import { highlightSearchText } from 'utils/helpers/textHelpers';
import { SearchOption } from '../definitions';
import SearchIcon from '@mui/icons-material/Search';
import SearchAndShowProvider, { SearchAndShowProviderProps } from '../search-and-show-provider';
import { useSearchAndShowProvider } from '../use-search-and-show-provider';

export const SearchAndShow: React.VFC<Omit<SearchAndShowProviderProps, 'children'>> = (props) => {
  return (
    <SearchAndShowProvider {...props}>
      <SearchAndShowInput />
    </SearchAndShowProvider>
  );
};

const SearchAndShowInput = () => {
  const {
    id,
    placeHolder,
    value,
    isDisabled,
    isSearching,
    allowFreeText,
    allowMultiple,
    noOptionsText,
    options,
    filterSelectedOptions,
    highlightSearchValue,
    showOpen,
    errors,
    touched,
    handleOpen,
    handleClose,
    filterOptions,
    onChange,
    onInputChange,
    renderTags
  } = useSearchAndShowProvider();

  return (
    <Autocomplete
      id={id}
      open={showOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      value={value}
      disabled={isDisabled}
      loading={isSearching}
      freeSolo={allowFreeText}
      multiple={allowMultiple}
      fullWidth
      selectOnFocus={true}
      clearOnBlur={true}
      options={options}
      noOptionsText={noOptionsText}
      filterOptions={filterOptions}
      filterSelectedOptions={filterSelectedOptions}
      getOptionDisabled={(option: any) => option.optionDisabled === true}
      isOptionEqualToValue={(option: any, value: any) => {
        if (typeof value === 'string') {
          return option?.displayName === value;
        } else {
          return option.id === value?.id;
        }
      }}
      onChange={onChange}
      onInputChange={onInputChange}
      getOptionLabel={(option) => {
        const searchOption = option as SearchOption;
        if (typeof searchOption === 'string') {
          return searchOption;
        } else {
          return searchOption.displayName;
        }
      }}
      renderOption={(props, option) => {
        const searchOption = option as SearchOption;
        return (
          <li {...props} key={`${searchOption.displayName}${searchOption.optionalDisplayName}`}>
            <span dangerouslySetInnerHTML={{ __html: highlightSearchText(searchOption.displayName, highlightSearchValue) }} />
            {searchOption.optionalDisplayName && (
              <span style={{ marginLeft: '8px', fontStyle: 'italic' }}>({searchOption.optionalDisplayName})</span>
            )}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeHolder}
          autoComplete="new-password"
          error={touched && Boolean(errors)}
          helperText={touched && errors}
          InputProps={
            !value || (Array.isArray(value) && (value as []).length === 0)
              ? {
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }
              : { ...params.InputProps }
          }
        />
      )}
      renderTags={renderTags}
    />
  );
};
