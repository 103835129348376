import React, { useState } from 'react';
import { MILLISECONDS, IActivity } from 'types';
import { loggedInUser, useActivityService } from 'hooks';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { chunk, flatten } from 'lodash';
import _ from 'lodash';

export interface ActivityCache {
  isActivityLoading: boolean;
  activities: IActivity[];
  hasActivities: boolean;
  isSearchLoading: boolean;
  hasSearchResults: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  addToActivityCache: (newActivity: IActivity) => void;
  setActivityFilters: (filters: ActivityFilters | null) => void;
}

export interface ActivityFilters {
  activityTeamIds: string[];
  searchString: string;
  typeFilters: string[];
  filterStartDate?: string;
  filterEndDate?: string;
}

export const useActivityCache = (): ActivityCache => {
  const queryClient = useQueryClient();
  const activityService = useActivityService();
  const { isLoggedIn, userId, myTeams } = loggedInUser.useController();
  const [activityFilters, setActivityFilters] = useState<ActivityFilters | null>(null);

  const teamIds = React.useMemo(() => _.sortBy(myTeams.map((t) => t.id)), [myTeams]);
  const activityPageCount = 25;

  const myActivitiesKey = React.useMemo(() => ['myActivities', userId, { teams: teamIds }], [teamIds]);
  const searchActivitiesKey = React.useMemo(
    () => [
      'searchActivities',
      {
        teams: Boolean(activityFilters?.activityTeamIds.length) ? activityFilters?.activityTeamIds : teamIds,
        searchString: activityFilters?.searchString,
        typeFilters: activityFilters?.typeFilters,
        startDate: activityFilters?.filterStartDate
      }
    ],
    [activityFilters]
  );

  const query = useInfiniteQuery({
    queryKey: myActivitiesKey,
    queryFn: ({ pageParam }) => activityService.getMyActivities(teamIds, pageParam, activityPageCount),
    initialPageParam: 0,
    enabled: isLoggedIn,
    staleTime: Infinity,
    getNextPageParam: (lastPage) => (lastPage.activityCount >= lastPage.pageSize ? lastPage.pageIncrement + 1 : undefined)
  });

  const searchQuery = useInfiniteQuery({
    queryKey: searchActivitiesKey,
    queryFn: ({ pageParam }) =>
      activityService.getMyActivities(
        Boolean(activityFilters?.activityTeamIds.length) ? activityFilters?.activityTeamIds! : teamIds,
        pageParam,
        activityPageCount,
        activityFilters?.searchString,
        activityFilters?.typeFilters,
        activityFilters?.filterStartDate,
        activityFilters?.filterEndDate,
        Boolean(activityFilters?.activityTeamIds.length) && !_.isEqual(activityFilters?.activityTeamIds, teamIds)
      ),
    initialPageParam: 0,
    enabled: activityFilters != null && (!Boolean(activityFilters?.searchString.length) || activityFilters?.searchString.length > 2),
    staleTime: MILLISECONDS.MINUTE,
    getNextPageParam: (lastPage) => (lastPage.activityCount >= lastPage.pageSize ? lastPage.pageIncrement + 1 : undefined)
  });

  const addToActivityCache = (newActivity: IActivity) => {
    if (activityFilters != null) {
      queryClient.invalidateQueries({ queryKey: searchActivitiesKey });
    }

    queryClient.setQueryData(myActivitiesKey, (oldData: any) => {
      const allActivities = [newActivity, ...flatten(query.data?.pages.map((r) => r.activities))];
      const pagedActivities = chunk(allActivities, activityPageCount);

      const newPagesArray = oldData.pages.map((page: any, index: number) => {
        return {
          ...page,
          activities: pagedActivities[index]
        };
      });

      return {
        pages: newPagesArray,
        pageParams: oldData.pageParams
      };
    });
  };

  let activities = query.data?.pages ? flatten(query.data?.pages.map((r) => r.activities)) : [];
  let isActivityLoading = query.isPending || query.isLoading;
  let hasActivities = Boolean(activities.length);
  let hasNextPage = query.hasNextPage && !isActivityLoading;
  let fetchNextPage = query.fetchNextPage;
  let isSearchLoading = false;
  let hasSearchResults = false;

  if (activityFilters != null) {
    activities = searchQuery.data?.pages ? flatten(searchQuery.data?.pages.map((r) => r.activities)) : [];
    isSearchLoading = searchQuery.isPending || searchQuery.isFetching;
    hasNextPage = searchQuery.hasNextPage && !isSearchLoading;
    fetchNextPage = searchQuery.fetchNextPage;
    hasSearchResults = Boolean(activities.length);
  }

  return {
    isActivityLoading,
    isSearchLoading,
    activities,
    hasActivities,
    hasSearchResults,
    hasNextPage,
    fetchNextPage,
    addToActivityCache,
    setActivityFilters
  };
};
