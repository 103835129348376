import { loggedInUser, useTaskService } from 'hooks';
import { MILLISECONDS } from 'types';
import { useQuery } from '@tanstack/react-query';

export const useTaskClosedReasonsCache = () => {
  const taskService = useTaskService();
  const { isLoggedIn } = loggedInUser.useController();

  const query = useQuery({
    queryKey: ['closedReasons'],
    queryFn: () => taskService.getTaskClosedReason(),
    staleTime: MILLISECONDS.HOUR * 24,
    enabled: isLoggedIn
  });

  const { isLoading: isClosedReasonsLoading, data: closedReasons = [] } = query;

  return {
    isClosedReasonsLoading,
    closedReasons,
    hasClosedReasons: closedReasons.length > 0
  };
};
