import appSettings from '../constants/appsettings.json';

export const useEnvironment = () => {
  const isProd = appSettings.environment === 'prod';
  const features = appSettings.features;

  return {
    isProd,
    features
  };
};
