import { SearchMembers, SearchTasks } from 'packages/search-and-show';
import globalSearchContext from './use-global-search';

const GlobalSearchSelector: React.VFC = () => {
  const { searchType, currentMemberId, currentTaskId, handleMemberSelect, handleTaskSelect } = globalSearchContext.useController();

  if (searchType === 'member') {
    return (
      <SearchMembers
        key={currentMemberId}
        memberId={currentMemberId}
        id="searchMember"
        placeholder="Search by Name, Phone or DOB..."
        onSelect={handleMemberSelect}
      />
    );
  }

  return (
    <SearchTasks
      key={currentTaskId}
      taskId={currentTaskId}
      id="searchTask"
      placeholder="Search by Task ID..."
      onSelect={handleTaskSelect}
    />
  );
};

export default GlobalSearchSelector;
